import React from "react";
import {runInAction} from "mobx";
import $ from "@isf/core-object-util";
import {localeStore} from "@isf/core-localization";
import {createAlertMessage} from "../../alert/alert-message";
import {
  getFileAsBase64EncodedRequestObj,
  getMinifySignRequestObj,
  getSignRequestObj,
  fileObj,
  getSignerRequestObj,
  getVerifyCmsRequestObject
} from "./file-input-constans";
import appStore from "@isf/core-app-store";

const LIST_SIGN_ACTION_BY_TYPE = {
  "db": [getFileAsBase64Encoded, getSignature, getMinifySignature, updateFullSignature],
};

const LIST_SIGN_OBJECT_ACTION_BY_TYPE = {
  "db": [getBase64, getSignature, getMinifySignature, storeSign],
};

const ERROR_MESSAGE_ID_BY_NAME = {
  "fileAsBase64": {
    "404": "ui.signFile.action.error.fileId.invalid",
    "500": "ui.action.error.500",
    "emptyFile": "ui.signFile.action.error.fileId.empty"
  },
  "getSignature": {},
  "getMinify": {
    "invalid": "ui.signFile.action.error.sign",
  },
  "getSigner": {
    "invalid": "ui.signFile.action.error.sign"
  },
  "verifyCms": {
    "429": "ui.sign.verify.error.too.many.requests",
    "400":"ui.sign.verify.error.bad.request",
    "500": "ui.action.error.500",
  }
};

function showErrorMessage(messageId = "ui.signFile.action.error") {
  const defMessage = $.get(localeStore.localeMessages, [messageId]);
  createAlertMessage(defMessage);
}

function showOkMessage() {
  const defMessage = $.get(localeStore.localeMessages, ["ui.signFile.action.success"]);
  createAlertMessage(defMessage, "success");
}

function generateThrow(messageId, errorMessage) {
  showErrorMessage(messageId);
  throw new Error(errorMessage);
}

function responseHandler (funcName, {context, response, body}) {
  let messageId;

  if (response.ok) {
    return body;

  } else if (response.status > 399) {
    messageId = ERROR_MESSAGE_ID_BY_NAME[funcName][response.status];
    messageId = messageId || "ui.signFile.action.error";
    return  showErrorMessage(messageId);
  }

  showErrorMessage(messageId);
  throw new Error("Bad request " + response.status);
}

const signFile = async (props, listener) => {
  await executeSignFlow(props, LIST_SIGN_ACTION_BY_TYPE["db"], listener);
};

const signObject = async (props, listener) => {
  await executeSignFlow(props, LIST_SIGN_OBJECT_ACTION_BY_TYPE["db"], listener);
}

const executeSignFlow = async (props, signFlow, listener) => {
  if (!signFlow) {
    typeof listener === "function" && listener(false);
    return;
  }

  let body;
  try {
    for (let i = 0; i < signFlow.length; i++) {
      body = await signFlow[i]({props, listener}, body);
    }
  } catch (e) {
    typeof listener === "function" && listener(false);
    console.error("Failed sign", e);
  }
}

async function getBase64(config) {
  const {props} = config;
  const {store, accessor} = props;

  const data = JSON.stringify($.toJS(store.get(accessor)));
  return btoa(encodeURIComponent(data));
}

async function getFileAsBase64Encoded(config) {
  const {props} = config;
  const {store, accessor, contextPath} = props;
  const fileId = store.get(accessor + ".id");

  if (!fileId) {
    generateThrow(ERROR_MESSAGE_ID_BY_NAME["fileAsBase64"]["emptyFile"], "File is empty");
  }

  const requestObj = getFileAsBase64EncodedRequestObj(contextPath, fileId);
  const response = await store.fileApi.call(requestObj);
  return await responseHandler("fileAsBase64", response);
}

async function getSignature(config, body) {
  const {store, isDetached} = config.props;
  const globalSign = appStore.getStore("globalSignStore", "ui");
  const type = globalSign.get(["actionConfig", "destination"]),
    port = globalSign.get(["actionConfig", "port"]);

  const requestObj = getSignRequestObj(body, port, isDetached, type);
  const response = await store.fileApi.call(requestObj);
  return await responseHandler("getSignature" , response);
}

async function verifyCms(config, body) {
  const {store} = config.props;
  const globalSign = appStore.getStore("globalSignStore", "ui");
  const type = globalSign.get(["actionConfig", "destination"]),
      port = globalSign.get(["actionConfig", "port"]);

  const requestObj = getVerifyCmsRequestObject(body, port);
  const response = await store.fileApi.call(requestObj);
  return await responseHandler("verifyCms" , response);
}

async function getMinifySignature(config, body) {
  const {isDetached} = config.props;

  if (isDetached) {
    return getSigner(config, body)
  } else {
    return getMinifySignatureAndSigner(config, body)
  }
}

async function getMinifySignatureAndSigner(config, body) {
  const {store} = config.props;

  if (!$.get(body, "cms") && !$.get(body, "cok")) {
    generateThrow(ERROR_MESSAGE_ID_BY_NAME["getMinify"]["invalid"], "Sign is empty");
  }

  try {
    body = JSON.stringify(body);
  } catch (e) {
    generateThrow(ERROR_MESSAGE_ID_BY_NAME["getMinify"]["invalid"], "Invalid sign");
  }

  const globalSign = appStore.getStore("globalSignStore", "ui");
  const type = globalSign.get(["actionConfig", "destination"]);
  const requestObj = getMinifySignRequestObj(body, type);

  const response = await store.fileApi.call(requestObj);
  return await responseHandler("getMinify", response);
}

async function getSigner(config, body) {
  const {store} = config.props;

  if (!$.get(body, "cms") && !$.get(body, "cok")) {
    generateThrow(ERROR_MESSAGE_ID_BY_NAME["getSigner"]["invalid"], "Sign is empty");
  }

  let sBody;
  try {
    sBody = JSON.stringify(body);
  } catch (e) {
    generateThrow(ERROR_MESSAGE_ID_BY_NAME["getSigner"]["invalid"], "Invalid sign");
  }

  const requestObj = getSignerRequestObj(sBody);
  const response = await store.fileApi.call(requestObj);
  const signerResp = await responseHandler("getSigner", response);

  return {
    ...body,
    ...signerResp
  };
}

function setFileObject(props, signature) {
  const {store, accessor, onChange, dst} = props;
  const obj = store.get(accessor);

  const fileInfo = $.toJS(obj);
  const id = $.get(fileInfo, "id"),
    name = $.get(fileInfo, "name"),
    type = $.get(fileInfo, "type");

  store.set(accessor, fileObj({name, type}, fileInfo, undefined, signature));
  showOkMessage();

  if (onChange) {
    onChange();
  }

  setSignToDstStore(props, signature);
}

function updateFullSignature(config, body) {
  const {props, listener} = config;

  runInAction(() => {
    const signature = [{
      signer: $.get(body, "signer.oid_full_name"),
      sign: $.get(body, "cms")
    }];

    setFileObject(props, signature);
    typeof listener === "function" && listener(true);
  })
}

function storeSign(config, body) {
  const {props} = config;

  runInAction(() => {
    const signature = [{
      signer: $.get(body, "signer.oid_full_name"),
      sign: $.get(body, "cms")
    }];

    setSignToDstStore(props, signature);
  })
}

function setSignToDstStore(props, signature) {
  const {dst} = props;

  if (!dst || !signature) {
    return;
  }
  const parts = dst.split(",");
  const dstStore = appStore.getDataStore(parts[0]);
  dstStore.set(parts.slice(1), signature[0].sign);
}

export {signFile, signObject, showErrorMessage, getFileAsBase64Encoded, verifyCms};