// import React from "react";
import { action, computed, observable, runInAction } from "mobx";
import merge from "deepmerge";
import appStore, { DataStore, routerStore, UIStore } from "@isf/core-app-store";
// import { localeStore } from "@isf/core-localization";
import { localeStore, registerBundle } from "@isf/core-localization";
// import { getEnvVariable } from "@isf/core-system-util";
import { getMicrofrontend } from "./microfrontend";
import actions from "./actions";

import $ from "@isf/core-object-util";
import { RestApi } from "@isf/core-rest";

import { isNewObjectPath, pathnameToTemplate, storeTemplToPath, templToPath } from "./util";

import { registerStore, resolveStore, stores as storesFromUiSchema } from "./ui-schema-stores";
import { restResponseHandler } from "./rest-response-handler/rest-response-handler";
// import { stores } from "./ui-schema-stores"
import { ApiCallActionStore } from "./api-call-action/api-call-action-store";
import { getDeclaredString } from "./expression/utils";
import { fileAttach } from "./file-attach";
import { createJSFunctionByConfig, getFunctionArguments } from "./expression/js-function";
import { createAlertMessage } from "./create-alert-message";
import { hostnameInfo, signFile , signObject} from "@isf/common";
import { dataWrapper } from "./api-call-action/data-wrapper";

import { default as accessSchema } from "./schema-samples/access-page";
import { default as page404Schema } from "./schema-samples/page-404";
import { default as inactiveAccountTemplate } from "./schema-samples/inactive-account-template";

import {
    getApplicationHostName,
    getInactiveLAXPageContextPath,
    getInactivePageContextPath,
    getPathToPageContent
} from "./app-info";

import { API_DEFAULT_CONF, callActionApi, confApi, fileApi, identityURL, restApi } from "./api";
import { createStores } from "./actions/create-stores";
import { executeDataTransferAction } from "./actions/data-transfer-action";
import { getScope } from "./ui-schema-security";
/*
export const API_DEFAULT_CONF = {
  "schema": getEnvVariable('API_DEFAULT_SCHEMA'),
  "host": getEnvVariable('API_DEFAULT_HOST'),
  "port": getEnvVariable('API_DEFAULT_PORT'),
  "context": getEnvVariable('API_DEFAULT_CONTEXT') + '/conf'
}

const identityURL = () => {
  const schema = getEnvVariable('API_IDENTITY_SCHEMA');
  const host = getEnvVariable('API_IDENTITY_HOST');
  const port = getEnvVariable('API_IDENTITY_PORT');
  return `${schema}://${host}:${port}`;
}

const confApi = new RestApi({ ...API_DEFAULT_CONF });
const userApi = new RestApi({ ...API_DEFAULT_CONF, ...{ context: 'api' } });
const restApi = new RestApi({ ...API_DEFAULT_CONF, ...{ context: 'api' } });
const fileApi = new RestApi({
  ...API_DEFAULT_CONF,
  ...{ context: 'api' },
  handler: new FileRestHandler()
});*/


window.onRouteChange = (...args) => {
    console.log("onRouteChange: " + args);
    routerStore.push(location.pathname);
} // for external microfrontends

// localeStore.initialize();
// const apiCallAction = new ApiCallActionStore({});

// const loadBundle = async (locale) => { //temporary
//   const bundles = await confApi.request({ path: 'bundles/v1' });
//   const localeBundles = bundles.filter(bundle => bundle.locale === locale);
//   const resultBundle = { code: bundles[0].code, locale, messages: {} };
//   localeBundles.forEach(bundle => {
//     resultBundle.messages = { ...resultBundle.messages, ...bundle.messages };
//   });
//   return resultBundle.messages;
// };

// registerBundle({
//   code: 'service',
//   load: async (locale) => {
//     const messages = await loadBundle(locale);
//     console.log('registerBundle', messages);
//     return messages;
//   }
// });

// localeStore.setLocale('ru');
// console.log('!! localeStore', localeStore);

localeStore.addLocaleChangeListener( async ({ locale }) => {
    console.log('microfrontend change', locale)
    const microfrontend = await getMicrofrontend(routerStore.pathname);
    microfrontend && microfrontend.callbacks.render({ locale })
});

// const loadSchema = async (pathname) => {
//    const filename = pathnameToTemplate(pathname);
//    const schema = await import('./schema-samples/' + filename);
//    (schema && $.get(schema, 'ui.children.1.children.1.children.1')) && $.remove(schema, 'ui.children.1.children.1.children.1'); //temporary
//    return schema;

//    // const parts = pathname.split('/');
//    // if (parts.length === 2) {
//    //   return await import('./schema-samples' + pathname);
//    // } else {
//    //   return await import('./schema-samples/' + parts[1]+'-id');
//    // }
// }

// const api = new RestApi({context: 'api'})
const uploadFile = () => {

}

// const getApplicationHostName = () => {
//   let hostname = "account";

//   if(getEnvVariable("ENV_TYPE") === "DEV.LOCAL" && hostnameInfo.isAdmin) {/*hostnameInfo.isLocalhost*/
//     // hostname = "localhost";
//     hostname = "admin";

//   } else if (hostnameInfo.isAdminHost) {
//     hostname = "admin";
//   }

//   return hostname;
// };

// const getMicrofrontends = async () => {
//   const microfrontends = await restApi.request({
//     path: "/confapp/microfrontend/v1/search",
//     options: {
//       method: "post",
//       body: JSON.stringify({
//         filter: {
//           "app": {
//             "host_name": [{
//               value: "account",
//               operation: "equals"
//             }]
//           }
//         }
//       })
//     }
//   });
//   return microfrontends;
// }

const ACCOUNT_APP_PG_TEMPLATE = '/configuration/v1/f83d7534-06df-44b9-b117-b4c87d5bc649/uiComponents';


class Engine {

    constructor() {
        window.addEventListener('popstate', function(event) {
           window.location.reload();
        }, false);
    }

    @observable.ref
    schema;

    @observable
    pageLoading = false;

    apis = {};

    pageTemplates = {};
    pageTemplatesBundles = {};
    uiComponents = {};
    langBundles = {};
    jsFunction = {};


    async loadBundlesByContext(ctxpath) {
        await registerBundle({
            code: ctxpath,
            load: async (lang) => {
                const bundle = await engine.loadBundle(lang, ctxpath);
                return bundle.messages;
            }
        }, true /* force load */);
    }

    async loadBundle(locale, ctxpath) {
        let bundles;
        const cashedBundles = $.get(engine.langBundles,[ctxpath,locale]);
        if (cashedBundles){
            return cashedBundles;
        }else {
            // bundles = await await confApi.request({
            //     path: '/bundles/v1/search',
            //     options: {
            //         method: 'post',
            //         body: JSON.stringify({
            //             filter: {
            //                 contextPath: [
            //                     {operation: "like", value: `/${ctxpath}%`}
            //                 ],
            //                 locale: [
            //                     {operation: "equals", value: locale}
            //                 ]
            //             }
            //         })
            //     }
            // });
            bundles = { data: [] };
            if (this.isValidCtxPath(ctxpath)) {
                bundles = await confApi.request({
                    path: '/bundles',
                    queryParams: {
                        contextPath: encodeURI(`/${ctxpath}`),
                        locale: encodeURI(locale)
                    }
                })
            }
            const ctxBundle = { messages: {} };
            bundles.data.forEach(b => {
                ctxBundle.messages = {...ctxBundle.messages, ...b.messages }
            } );
            $.set(engine.langBundles,[ctxpath,locale],ctxBundle);
            return ctxBundle;
        }
    };

    async loadApplication() {
        const hostname = getApplicationHostName();

        const application = await restApi.request({
            path: "/confapp/application/v1/search/x-short",
            options: {
                method: "post",
                body: JSON.stringify({
                    filter: {
                        host_name: [
                            {operation: "equals", value: hostname}
                        ]
                    }
                })
            }
        });

        return application;
    }

    async loadPermissions(uri) {
        return await restApi.request({
            path: `permission/search`,
            options: {
                method: "post",
                body: JSON.stringify({
                    uri: uri
                })
            }
        });
    }

    async loadTemplPermissions() {
        const permissionObj = await this.loadPermissions($.get(this.pageTemplates, "main_template.contextPath"));
        return $.get(permissionObj, "permissions");
    }

    getTemplateConfigurationId() {
        return $.get(this.pageTemplates, "main_template.configurationId")
    }

    getPageContextPath() {
        let ctxpath = routerStore.get('path').slice(0, 3).join('/');
        const userStore = appStore.getStore("user", "user");

        if (userStore.isInactiveAccount) {
            return getInactivePageContextPath();
        }

        if (userStore.isLAXInactiveAccount) {
            if (window.location.pathname !=="/"+ getInactiveLAXPageContextPath()) {
                window.location = "/"+ getInactiveLAXPageContextPath() + "?type=" + userStore.getIdpName;
            }
        }

        if (!ctxpath) {
            ctxpath = $.get(this.pageTemplates, "main_page.contextPath");
            ctxpath = typeof ctxpath === "string"
                ? ctxpath.split("/").slice(1, 4).join("/")
                : undefined;
        }

        return ctxpath;
    }

    getPermissionUri() {
        let url = routerStore.pathname + routerStore.search;
        if (routerStore.pathname === "/") {
            url = $.get(this.pageTemplates, "main_page.contextPath");
        }

        const userStore = appStore.getStore("user", "user");
        if (userStore.isInactiveAccount) {
            url = "/" + getInactivePageContextPath();
        }

        if (userStore.isLAXInactiveAccount) {
            url = "/" + getInactiveLAXPageContextPath();
        }

        return url;
    }

    initializeTemplate(placeAtPath, pathName) {
        placeAtPath = $.strToArray(placeAtPath);
        if (placeAtPath.pop() === "children") {
            placeAtPath.push("children");
        }
        $.set(this.pageTemplates, "main_template.schema.ui." + placeAtPath.join("."), []);

        runInAction(() => {
            this.createDefaultStores();
            const userStore = appStore.getStore("user", "user");
            this.schema = $.get(this.pageTemplates, "main_template.schema");
            if (userStore.isInactiveAccount || userStore.isLAXInactiveAccount) {
                this.schema = inactiveAccountTemplate;
            }
            createStores(this.schema);
            this.createJSFunction(this.schema);
            this.executeOnLoadActions(this.schema, undefined, undefined, pathName);
        });
    }

    async loadPageTemplate() {
        if (!this.pageTemplates[ACCOUNT_APP_PG_TEMPLATE]) {
            this.pageTemplates[ACCOUNT_APP_PG_TEMPLATE] = await confApi.request({ path: ACCOUNT_APP_PG_TEMPLATE });
        }
        // const template = this.pageTemplates[ACCOUNT_APP_PG_TEMPLATE];
        const template = this.pageTemplates[ACCOUNT_APP_PG_TEMPLATE] = await confApi.request({ path: ACCOUNT_APP_PG_TEMPLATE });

        // const newTemplate = await confApi.request({ path: ACCOUNT_APP_PG_TEMPLATE });
        runInAction(() => {
            createStores(this.schema);
            this.createDefaultStores();
            this.schema = template[1].schema;
            this.createJSFunction(this.schema);
            this.executeOnLoadActions(this.schema);
        });
    }

    async loadUIComponents(ctxpath,operation) {
        if (this.uiComponents[ctxpath]) {
            return this.uiComponents[ctxpath];
        }
        const moduleComponents = await this.getUIComponents(operation||'like', ctxpath);
        this.uiComponents[ctxpath] = moduleComponents.data;
        // console.log('loadSchema pathname', pathname, pathnameToTemplate(pathname, '/'));
        return moduleComponents.data;
    }

    /**
     * @deprecated
     * @param uiComponent
     * @return {Promise<void>}
     */
    async loadRefs(uiComponent) {
        if (uiComponent && $.get(uiComponent, 'schema.refs')) {
            const refs = $.get(uiComponent, 'schema.refs');
            for (let refKey in refs) {
                let resultContextPath = $.get(refs, refKey + '.contextPath');
                const response = await this.getUIComponents('equals', resultContextPath);
                const resultUIComponent = $.get(response, 'data')[0] || {};
                if (resultUIComponent) {
                    const globalRefsStore = appStore.getDataStore('globalRefsStore');
                    if (globalRefsStore) {
                        const resultSchema = $.get(resultUIComponent, 'schema');
                        createStores(resultSchema);
                        if (resultContextPath[0] === '/') {
                            resultContextPath = resultContextPath.substr(1, resultContextPath.length)
                        }
                        const ctxPathForBundles = resultContextPath.split('/').length >= 3 ? resultContextPath.split('/').slice(0, 3).join('/') : resultContextPath;
                        await this.loadBundlesByContext(ctxPathForBundles);
                        this.executeOnLoadActions(resultSchema);
                        globalRefsStore.set('refs.' + refKey + '.schema', resultSchema);
                    }
                }
            }
        }
    }

    isValidCtxPath(ctxpath) {
        const isStr = typeof ctxpath === 'string';
        const length = isStr && ctxpath.startsWith('/')
          ? 3
          : 2;
        const isCtxPath = isStr && ctxpath.split('/').length >= length
        return isCtxPath
    }

    async getUIComponents(operation, ctxpath) {
        if (!this.isValidCtxPath(ctxpath)) {
            return {
                data: []
            };
        }

        const contextPath = operation === 'equals' ? ctxpath : `/${ctxpath}`;

        return await confApi.request({
            path: '/uiComponents',
            queryParams: {
                contextPath: encodeURI(contextPath),
                operation,
            }
        })
        // return await confApi.request({
        //     path: '/uiComponents/v1/search',
        //     options: {
        //         method: 'post',
        //         body: JSON.stringify({
        //             filter: {
        //                 contextPath: [
        //                     {operation: operation, value: operation === 'equals' ? ctxpath : `/${ctxpath}%`}
        //                 ]
        //             },
        //             paging: {
        //                 page:1,
        //                 limit: 200
        //             }
        //         })
        //     }
        // })
    }

    @action
    async loadSchema(pathname, placeAtPath, components) {

        //routerStore.get('path').slice(0, 3).join("/");
        // const messages = await this.loadBundles('ru', ctxpath);
        // console.log('messages', messages);
        // this.registerMessages(messages);
        let uiComponents = components;
        if(!uiComponents) {
            const ctxpath = this.getPageContextPath();
            uiComponents = await this.loadUIComponents(ctxpath);
        }

        // console.log('pathname',pathname)
        const pathtemplate = pathnameToTemplate(pathname, '/').split('/').slice(1).join('/'); // temporary
        const uiComponent = uiComponents.find(uic => {
            return (uic.contextPath === pathtemplate) || (uic.contextPath === '/' + pathtemplate);
        });
        // console.log(uiComponent);
        const microfrontend = await getMicrofrontend(pathname);
        if (microfrontend) await import('@isf/ckeditor');
        this.schema.microfrontend = microfrontend;
        let schema = microfrontend ? microfrontend.schema : undefined;
        let loadPermission = !schema;

        // console.log('Microfrontend found:', microfrontend);

        if (!schema) {
            // if (pathname.includes('/umgt/group/')) {
            //   schema = {
            //     'ui': {
            //       'tag': 'GroupsView'
            //     }
            //   };
            // } else

            if (!uiComponent && routerStore.pathname === "/") {
                return;

            } else if (!uiComponent) {
                loadPermission = false;
                schema = page404Schema;

            } else {
                schema = uiComponent.schema;
            }
        }

        // console.log('SCHEMA', schema);

        const userStore = appStore.getStore("user", "user");
        const resourceId = schema.configurationId;
        if ((resourceId || loadPermission) && !hostnameInfo.isAdmin) {
            const uri = this.getPermissionUri();
            const permissionObj = await this.loadPermissions(uri);
            const permissions = $.get(permissionObj, "permissions");
            // restApi.request({
            //   // path: `permission/${resourceId}`
            //   path: `permission/search`,
            //   options: {
            //     method: "post",
            //     body: JSON.stringify({
            //       uri: uri
            //     })
            //   }
            // });

            if (
                // !hostnameInfo.isAdminHost
                // && !hostnameInfo.isLocalhost &&
                $.get(permissionObj, "forbid")
                || (
                    (!$.isArray(permissions) || permissions.length === 0)
                    && !$.get(permissionObj, "permit")
                )
            ) {
                schema = accessSchema;
            }
            // console.log("appStore", appStore);
            userStore.setResourcePermissions(resourceId, permissions)
            //  console.log('userStore', userStore);
        }

        // const schema = await loadSchema(pathname);

        const stores = $.get(schema, ['func', 'stores', 'data']);
        if (stores) {
            for (const store in stores) {
                if (stores[store].type === 'ServiceDesignStore' && (!storesFromUiSchema
                    || (typeof storesFromUiSchema === 'object' && !storesFromUiSchema.hasOwnProperty(stores[store].type)))) {
                    const ServiceDesignStore = await import("../../sdk/design-components/src/designers/service/service-design-store");
                    registerStore({type: 'ServiceDesignStore', store: ServiceDesignStore.default});
                } else if (stores[store].type === 'PageTemplateDesignStore' && (!storesFromUiSchema
                    || (typeof storesFromUiSchema === 'object' && !storesFromUiSchema.hasOwnProperty(stores[store].type)))) {
                    const PageTemplateDesignStore = await import("../../sdk/design-components/src/designers/page-template/page-template-design-store");
                    registerStore({type: 'PageTemplateDesignStore', store: PageTemplateDesignStore.default});
                } else if (stores[store].type === 'DesignContext' && (!storesFromUiSchema
                    || (typeof storesFromUiSchema === 'object' && !storesFromUiSchema.hasOwnProperty(stores[store].type)))) {
                    const DesignContext = await import("../../sdk/design-components/src/design-context/design-context");
                    registerStore({type: 'DesignContext', store: DesignContext.default});
                }
            }
        }
        runInAction(() => {
            this.onLoad(schema, placeAtPath, pathname);
            //  if (microfrontend) {
            //     // console.log('microfrontend.callbacks',microfrontend.callbacks);
            //     try {
            //        // console.log(document.getElementById('page-content'));
            //        // microfrontend.callbacks && microfrontend.callbacks.render();
            //     } catch (e) {
            //        console.error(e);
            //     }
            //  }
        });
    }

    async reloadPermissions(pathname, placeAtPath) {//TODO: rewrite
        placeAtPath = $.strToArray(placeAtPath);
        const resourceId = $.get(this.schema, ["ui", ...placeAtPath, "configurationId"]);
        if ((resourceId) && !hostnameInfo.isAdmin) { //TODO: maybe check is page microfronted or page 404;
            const uri = this.getPermissionUri();
            const permissionObj = await this.loadPermissions(uri);
            const permissions = $.get(permissionObj, "permissions");

            const userStore = appStore.getStore("user", "user");
            userStore.setResourcePermissions(resourceId, permissions);
            if (
                $.get(permissionObj, "forbid")
                || (
                    (!$.isArray(permissions) || permissions.length === 0)
                    && !$.get(permissionObj, "permit")
                )
            ) {
                runInAction(() => {
                    this.onLoad(accessSchema, placeAtPath, pathname);
                });
            } else {
                const schema = this.schema;
                this.schema = undefined;
                this.schema = schema;
            }
        }
    }

    @computed
    get uiSchema() {
        return this.schema ? this.schema.uiSchema : undefined;
    }

    onLoad(schema, placeAtPath, pathName) {
        if (this.schema && placeAtPath) {
            const newSchema = {...this.schema};
            newSchema.func = schema.func;
            newSchema.templates = schema.templates;
            newSchema.refs = schema.refs;
            $.set(newSchema.ui, placeAtPath, schema.ui);
            this.schema = newSchema;
        } else {
            this.schema = schema;
        }
        createStores(schema, {}, false);
        this.createDefaultStores();
        this.createJSFunction(schema);
        const refsKeys = $.keys(schema.refs || {});
        const globalRefsStore = appStore.getDataStore('globalRefsStore');
        for (let i = 0; i < refsKeys.length; i++) {
            globalRefsStore.set('refs.pathName.' + refsKeys[i], pathName)
        }
        this.executeOnLoadActions(schema, undefined, undefined, pathName);
    }

    destroyFunction() {
        this.jsFunction = {};
    }

    createJSFunction(schema, context = {}) {
        const actions = $.get(schema, ['func', 'actions']) || {};
        const names = $.keys(actions);
        names.forEach(name => {
            const config = $.get(actions, name);
            const type = $.get(config, ["type"]);

            if(type === "jsFunction") {
                const newName = context.refId ? context.refId + "." + name : name;
                this.jsFunction[newName] = createJSFunctionByConfig(config);
            }
        });
    }

    createDefaultStores() {
        new UIStore({
            path: 'globalExecuteActionsStore',
            state: {executeActions: new Map()},
            observableAsMap: true
        });
        new DataStore({
            path: 'globalRefsStore',
            state: {
                refs: {
                    config: {},
                    pathName: {},
                    params: $.objectToObservableMap({}),
                    contextPath: $.objectToObservableMap({})
                }
            },
            // observableAsMap: true
        });
        new UIStore({
            path: 'globalComputedStore',
            state: {functions: new Map()},
            observableAsMap: true
        });
        new UIStore({
            path: 'globalLoadedRefsStore',
            state: observable([]),
            observableAsMap: true
        });
    }

    getGlobalExecuteActionsStore() {
        let globalExecuteActionsStore = appStore.getStore('globalExecuteActionsStore', 'ui');

        if (!globalExecuteActionsStore) {
            globalExecuteActionsStore = this.createGlobalExecuteActionStore();
        }

        return globalExecuteActionsStore;
    }

    isSamePathNames(routerPathname, contextPathname){
        const userStore = appStore.getStore("user", "user");
        if (userStore.isInactiveAccount || userStore.isLAXInactiveAccount) {
            return true;
        }

        const arrCtxName = $.strToArray(contextPathname, "/");
        const routerCtxName = $.strToArray(routerPathname, "/");
        if (!$.isArray(arrCtxName) || !$.isArray(routerCtxName) || arrCtxName.length !== routerCtxName.length) {
            return false;
        }

        let flag = true;
        arrCtxName.forEach((segment, i) => {
            if (segment === "new" && routerCtxName[i] !== "new") {
                // if page new and we create new record url continue
            } else if (segment !== routerCtxName[i]) {
                flag = false;
            }
        });
        return flag
        // if(contextPathname === routerPathname) {
        //     return true;
        // }
        // }else{
        //     const splittedRouterPathname = routerPathname.split('/');
        //     if(splittedRouterPathname.length!==0){
        //         const lastElement = splittedRouterPathname[splittedRouterPathname.length-1];
        //         if(lastElement.startsWith('@')){
        //             splittedRouterPathname.splice(splittedRouterPathname.length-1,1);
        //             if(splittedRouterPathname.join('/') === contextPathname){
        //                 return true;
        //             }
        //         }
        //     }
        //     return false
        // }
    }

    async executeOnLoadActions(schema, path, refId, pathName) {
        const loadActions = $.get(schema, ['func', 'onLoad']);
        if (!loadActions) return;
        // actions && actions.forEach(action => {

        if (this.isSamePathNames(document.location.pathname, pathName)) {
            for (let i = 0; i < loadActions.length; i++) {
                try {
                    if (!this.isSamePathNames(document.location.pathname, pathName)) {
                        break;
                    }
                    const type = await this.executeAction(loadActions[i], {
                        schema: schema,
                        path: path,
                        refId: refId,
                        pathName: pathName
                    },undefined, undefined, undefined, true);
                    if (type === 'link' || type === "noExecute") {
                        break;
                    }
                } catch (e) {
                    console.log('failed to execute action', loadActions[i], e);
                }
            }
        }

        // );
    }

    resolveTemplates(refId) {
        let templates = $.get(this.schema, ['templates']);

        if (refId) {
            const globalRefsStore = appStore.getDataStore('globalRefsStore');
            templates = (globalRefsStore && globalRefsStore.get('refs.config.' + refId + '.templates')) || templates;

        }
        return templates;
    }

    resolveAction(action, context) {
        // console.log('resolving action', action);
        if (typeof action === 'object') {
            let a;
            if (action.ref) {
                action = this.resolveAction(action.ref, context);
            }
            const queryParams = $.get(action, 'request.queryParams');
            if (queryParams) {
                // a.request.queryParams = merge(queryParams, routerStore.stateJS.query);
                Object.keys(queryParams).forEach(key => {
                    const param = queryParams[key];

                    if (param && (typeof param === 'string') && param.startsWith('$')) {
                        const paramPath = param.substring(1).split('.');
                        if (paramPath.length > 1 && paramPath[0] === 'queryParams') {
                            queryParams[key] = routerStore.stateJS.query[paramPath[1]];
                        }
                        // console.log('queryParams[key]', key, queryParams[key])
                    }
                })


                // console.log('a', a.request.queryParams, a.request.queryParams.processInstanceId);
                // console.log('a', a);
            }
            a = a ? merge(action, a) : action;
            // const queryParams = $.get(a, 'request.queryParams');
            // if (queryParams) {
            //   // a.request.queryParams = merge(queryParams, routerStore.stateJS.query);
            //   Object.keys(queryParams).forEach(key => {
            //     const param = queryParams[key];
            //     if (param.)
            //   })
            // }
            return a;
            // return { ...this.resolveAction(action.ref), ...action};

        }
        const {refId, schema} = context;
        let resultSchema;
        if (refId) {
            const globalRefsStore = appStore.getDataStore('globalRefsStore');
            resultSchema = globalRefsStore.get('refs.config.' + refId);
        } else if (schema) {
            resultSchema = schema;
        } else {
            resultSchema = this.schema;
        }
        return $.get(resultSchema, ['func', 'actions', action]);
    }

    @action
    async executeAction(action, context, data, flowName, componentName, isOnLoad) {
        const actionName = action.ref ? action.ref : action;
        action = this.resolveAction(action, context);
        data = data && typeof(data) === "object" && "preventDefault" in data ? undefined : data;
        if (!this.actionShouldExecuteByCondition(action, data, context)) {
            return;
        }
        if (!action) {
            return;
        }
        if (actions[action.type]) {
            actions[action.type](action)
            return;
        }
        if (['apiCall', 'apiCallLocal'].includes(action.type)) {
            return this.executeApiCallAction(action, context, data, actionName, flowName, componentName, isOnLoad);
        } else if (action.type === 'request') {
            return this.executeRequestAction(action, context, actionName, flowName);
        } else if (action.type === 'flow') {
            const result = await this.executeFlowAction(action, context, data, actionName, componentName);
            if(result === 'link'){
                return result
            }
        } else if (action.type === 'rules') {
            this.executeRulesAction(action, context);
        } else if (action.type === 'toggle_modal') {
            this.executeUIAction(action, context);
        } else if (action.type === 'link') {
            this.executeLinkAction(action,context,data);
            return action.type;
        } else if (action.type === 'jsFunction') {
            this.executeJSFunction(action, context, data, actionName, flowName)
        } else if (action.type === 'sign' || action.type === 'signObject') {
            return this.executeSignAction(action, context, data, actionName, flowName, componentName)
        } else if (action.type === 'load_ref') {
            const noAsync = $.get(action,'async') === false;
            if(noAsync){
                await this.loadRefComponent(action, context, actionName);
            }else{
                this.loadRefComponent(action, context, actionName);
            }
        }else if (action.type === "data_transfer"){
            executeDataTransferAction(action,context, actionName);
        }
    }

    actionShouldExecuteByCondition(action, data, context) {
        // data = data && typeof(data) === "object" && "preventDefault" in data ? undefined : data;
        if ($.get(action, 'condition')) {
            const {params, expression} = $.get(action, 'condition');
            let declaredString = "";
            if (params.length !== 0) {
                declaredString = getDeclaredString(params, localeStore, data, context);
                return eval(declaredString + expression)
            } else {
                return eval(expression || 'true')
            }
        }
        return true;
    }


    executeUIAction(action, context = {}) {
        const store = appStore.get("ui." + action.storeName);
        store.set('active', !store.get('active'))
    }

    async loadRefComponent(action, context = {}, actionName) {
        const refId = $.get(action, 'refId');
        const parentRefId = context.refId;
        if (refId) {
            const globalActionsStore = this.getGlobalExecuteActionsStore();
            const executeActions = globalActionsStore.get('executeActions');
            executeActions.set(actionName, true);
            let ref = $.get(this.schema, 'refs.' + refId),
                pathName = $.get(context, 'pathName');
            const globalRefsStore = appStore.getDataStore('globalRefsStore');

            if (globalRefsStore.get('refs.contextPath.' + refId)) {
                // globalRefsStore.set('refs.' + refId + '.schema', undefined);
                globalRefsStore.set('refs.contextPath.' + refId, undefined);
            }

            if (parentRefId) {
                ref = globalRefsStore.get('refs.config.' + parentRefId + '.refs.' + refId);
                pathName = globalRefsStore.get('refs.pathName.' + parentRefId);
            }

            // let ref = $.get(newSchema, 'refs.' + refId);
            ref = $.isObservable(ref) ? $.toJS(ref) : ref;
            if (ref) {
                let contextPath;
                const {params, expression} = $.get(ref, 'contextPath') || {};
                let declaredString = "";
                if (params && params.length !== 0) {
                    declaredString = getDeclaredString(params, localeStore, undefined, context);
                    contextPath = eval(declaredString + expression);
                } else {
                    contextPath = eval(expression || '');
                }
                if (contextPath) {
                    // const shortCtxPath = contextPath.split('/')[1];
                    // const uiComponents = await this.loadUIComponents(shortCtxPath);
                    //---------------------------------------
                    const contextPathForEqualsSearch = this.getContextPathForEqualsSearch(contextPath);
                    const uiComponents = await this.loadUIComponents(contextPathForEqualsSearch, 'equals');
                    //---------------------------------------------------
                    let pathtemplate = contextPath.split("?")[0].split("#")[0];
                    pathtemplate = pathnameToTemplate(pathtemplate, '/').split('/').slice(1).join('/'); // temporary
                    const resultUIComponent = uiComponents.find(uic => {
                        return (uic.contextPath === pathtemplate) || (uic.contextPath === '/' + pathtemplate);
                    });
                    //const response = await this.getUIComponents('equals', contextPath);
                    //                const resultUIComponent = $.get(response, 'data')[0] || {};
                    if (resultUIComponent) {
                        // const globalRefsStore = appStore.getDataStore('globalRefsStore');
                        const configurationId = $.get(resultUIComponent, 'configurationId');
                        const existPermissions = await this.loadRefPermissions(refId, configurationId, contextPath);

                        if (globalRefsStore && existPermissions) {

                            const resultSchema = $.get(resultUIComponent, 'schema');
                            const storesMappings = $.get(ref,'storesMappings');
                            createStores(resultSchema, {refId, parentRefId}, false, storesMappings);
                            this.createJSFunction(resultSchema, {refId});
                            this.setQueryParamsInRefComponent(resultSchema, contextPath, globalRefsStore, refId);
                            let shortCtxPath = contextPath.split("?")[0].split("#")[0];
                            if (shortCtxPath[0] === '/') {
                                shortCtxPath = shortCtxPath.substr(1, shortCtxPath.length)
                            }
                            const ctxPathForBundles = shortCtxPath.split('/').length >= 3 ? shortCtxPath.split('/').slice(0, 3).join('/') : shortCtxPath;
                            await this.loadBundlesByContext(ctxPathForBundles);
                            globalRefsStore.set('refs.config.' + refId, resultSchema);
                            globalRefsStore.set('refs.contextPath.' + refId, shortCtxPath);
                            pathName && globalRefsStore.set('refs.pathName.' + refId, pathName);
                            /*await*/ this.executeOnLoadActions(resultSchema, shortCtxPath, refId, pathName);
                            //cacheStore.cacheUIStores('/'+(routerStore.get('path').join('/')),appStore.get('ui'));
                            const globalLoadedRefsStore = appStore.getStore('globalLoadedRefsStore', 'ui');
                            globalLoadedRefsStore.state.push(refId);
                            /*   const globalLoadedRefsStore = appStore.getStore('globalLoadedRefsStore', 'ui');
                               globalLoadedRefsStore.state.push(refId);*/
                        }
                    }
                }
            }
            executeActions.set(actionName, false);
        }
    }

    async loadRefPermissions(refId, configurationId, contextPath){
        const userStore = appStore.getStore("user", "user");
        // const mainConfigId = $.get(this.schema,'configurationId')
        if (contextPath && configurationId && !hostnameInfo.isAdmin) {
            const permissionObj = await this.loadPermissions(contextPath);
            const permissions = $.get(permissionObj, "permissions");
            // restApi.request({ path: `permission/${configurationId}`});
            // permissions.forEach(permission=>{
            //     userStore.addResourcePermission(mainConfigId,permission)
            // })
            userStore.setResourcePermissions(configurationId, permissions);

            if (
                $.get(permissionObj, "forbid")
                || (
                    (!$.isArray(permissions) || permissions.length === 0)
                    && !$.get(permissionObj, "permit")
                )
            ) {
                return false;
            }
        }

        return true;
    }

    getContextPathForEqualsSearch(contextPath){
        // let resultPath='';
        const splittedContextPath = this.splitContextPath(contextPath) || [];
        return pathnameToTemplate(splittedContextPath, "/");
        // for(let i=0;i<splittedContextPath.length;i++){
        //     if(splittedContextPath[i]){
        //         if(i%2===0 && i!==2){
        //             resultPath+='/{id}';
        //         }else{
        //             resultPath+='/'+splittedContextPath[i]
        //         }
        //     }
        // }
        // return resultPath;
    }

    splitContextPath(contextPath){
        let bufferContextPath = contextPath;
        const indexOfQuery = contextPath.indexOf('?');
        if(indexOfQuery === -1)
            return contextPath;//.split('/')
        else{
            return bufferContextPath.substr(0, indexOfQuery);
            // const pathTillQuery = bufferContextPath.substr(0, indexOfQuery);
            // return pathTillQuery.split('/')
        }
    }

    setQueryParamsInRefComponent (schema, contextPath, refStore, refId){
        const queryIndex = contextPath.lastIndexOf('?');
        if(~queryIndex){
            const queryString = contextPath.substr(queryIndex+1);
            const queryArray = queryString.split('&');
            for(let i=0;i<queryArray.length;i++){
                const queryParam = queryArray[i];
                const indexEqual = queryParam.lastIndexOf('=');
                // $.set(schema,'params.query.'+queryParam.substring(0,indexEqual),queryParam.substr(indexEqual+1))
                refStore.set("refs.params." + refId + ".query." + queryParam.substring(0,indexEqual), queryParam.substring(indexEqual+1))
            }
        }
        let pathString = ~queryIndex ? contextPath.substring(0,queryIndex) : contextPath;
        pathString = pathString.split("#")[0];
        if (pathString[0] === '/') {
            pathString = pathString.substr(1, pathString.length)
        }
        // $.set(schema,'params.path',pathString.split('/'));
        refStore.set("refs.params." + refId + ".path", pathString.split('/'))
    }

    executeLinkAction(action, context, data) {
        const computed = $.get(action, 'address.computed');
        const params = $.get(computed, 'params');
        const expression = $.get(computed, 'expression');
        const reloadPage = $.get(action, "options.reloadPage");
        let resultUrl;
        if (params && params.length !== 0) {
            let declaredString = "";
            if (params.length !== 0) {
                declaredString = getDeclaredString(params, localeStore, data, context);
                if (declaredString !== "let;") {
                    resultUrl = eval(declaredString + expression);
                } else {
                    resultUrl = expression;
                    resultUrl = eval(expression);
                }
            }
        } else if (expression) {
            resultUrl = expression;
            resultUrl = eval(expression);
        }
        if(typeof resultUrl === "string") {
            if (resultUrl.startsWith("http://") || resultUrl.startsWith("https://") || reloadPage) {
                document.location = resultUrl;
            } else {
                const processInstanceId = routerStore.get("query.processInstanceId"),
                    scope = routerStore.get("query.scope"),
                    taskId = routerStore.get("query.taskId");
                let pathname = routerStore.pathname;
                routerStore.mobxStore.location = resultUrl;

                if ((processInstanceId !== routerStore.mobxStore.location.query.processInstanceId
                    || scope !== routerStore.mobxStore.location.query.scope
                    || taskId !== routerStore.mobxStore.location.query.taskId)
                    && pathname === routerStore.mobxStore.location.pathname
                ) {
                    const ctxpath = engine.getPageContextPath();
                    pathname = pathname.startsWith("/" + ctxpath) ? pathname : "/" + ctxpath;
                    engine.reloadPermissions(pathname, getPathToPageContent());
                }
            }
        }
    }

    executeApiCallAction(action, context = {}, data, actionName, flowName, componentName, isOnLoad) {
        // const api = this.getAPI(action.api);
        const apiCallAction = new ApiCallActionStore({
            action,
            context,
            componentName,
            listData: data,
            setterNoReload: this.setNoReload
        });
        let globalExecuteActionsStore = this.getGlobalExecuteActionsStore();
        if ($.get(action, 'cacheFilter') && isOnLoad) {
           const store = appStore.getDataStore($.get(action, 'request.store'));
           const urlParams = new URLSearchParams(window.location.search);
           const prevFilter = urlParams.get(actionName);
           if (prevFilter) {
               const jsPrev = JSON.parse(prevFilter)
               Object.entries(jsPrev).forEach(([key, value])=> {
                   store.set(`body.${key}`, value)
               })
           }
        }
        const requestObj = apiCallAction.getRequestOptions({data:data, context:context});
        const request = requestObj.requestConf;
        const noExecute = requestObj.noExecute;
        if(noExecute){
            createAlertMessage(localeStore.localeMessages['ui.alerts.422'], 'error');//front validation message
            return "noExecute";
        }
        if ($.get(action, 'cacheFilter') && !isOnLoad) {
                const filter = $.get(request, 'options.body');
                const url = new URL(window.location.href);
                url.searchParams.set(actionName, filter);
                routerStore.push(url.pathname + url.search);
        }
        return request && callActionApi.call(
            request,
            {request, action, flowName, refId: context.refId, engineContext:this, callContext:context},
            apiCallAction.restHandler,
            actionName,
            globalExecuteActionsStore
        );
    }

    executeJSFunction(action, context = {}, data, actionName, flowName) {
        //data = data && "preventDefault" in data ? undefined : data;
        const args = getFunctionArguments(action, localeStore, data, context);

        const executeStore = this.getGlobalExecuteActionsStore();
        executeStore.set(["executeActions", actionName], true);

        try {
            const func = this.jsFunction[context.refId ? context.refId + "." + actionName : actionName];
            typeof func === "function" && func(...args);

        } catch (e) {
            const errorGlobalStore = appStore.getStore('errorGlobalStore', 'ui');
            if (flowName) {
                errorGlobalStore.set('failedFlow', flowName);
            }

            createAlertMessage([
                {tag: "p", text: "Failed execute js function", props: {className: "h5 mb-2"}},
                {tag: "p", text: `Function name: ${actionName}`},
                {tag: "p", text: `Error message: ${e.message}`}
            ]);

            console.error("Failed execute js function", e);
        }

        executeStore.set(["executeActions", actionName], false)
    }

    async executeSignAction(action, context = {}, data, actionName, flowName, componentName) {
        const globalSign = appStore.getStore("globalSignStore", "ui");
        const toggleStore = appStore.getStore("globalSign.ToggleStore", "ui");

        if(globalSign.get(["state"]) === "SLEEP") {
            if(toggleStore.get('active')) return;
            globalSign.set(["state"], "SETTINGS");
            globalSign.action = () => this.executeAction(actionName, context, data);

            if (~context["flow_index"]) {
                globalSign.set(["flow_index"], context["flow_index"]);
                globalSign.action = () => this.executeAction(flowName, context, data, undefined, componentName);
            }

            toggleStore && toggleStore.toggle && toggleStore.toggle();
            return;
        }

        const {store: storeName, accessor, contextPath, isDetached, dst} = action;
        const {refId} = context;

        let storePath = refId ? refId + "." + storeName : storeName;
        let store = appStore.getDataStore(storePath);

        if ($.isObject(data)) {
            const {store: listStore, index: listIndex} = data;
            const listData = listStore.data ? $.get(listStore.data, listIndex + "") : listStore.get(listIndex + "");
            if (listStore === store) {
                store = dataWrapper(listData);
                store.fileApi = fileApi;
            }
        }

        if(!accessor || !contextPath || !store || !store.set || !store.get) {
            const message = !accessor
                ? "Empty accessor"
                : !contextPath
                    ? "Empty context path"
                    : "Invalid store, store name: " + storeName;

            console.error("Sign file, invalid action. Action name: ", actionName, ".", message);
            createAlertMessage(localeStore.localeMessages['ui.action.sign.invalid'] + " " + actionName);
            // toggleStore && toggleStore.set && toggleStore.set('active', false);
            // globalSign.set("loading", false);
            return
        }


        const listener = (flag) => {
            if (!flag) {
                const errorGlobalStore = appStore.getStore('errorGlobalStore', 'ui');
                if (flowName) {
                    errorGlobalStore.set('failedFlow', flowName);
                }
            }
        };

        if (action.type === "sign") {
            return signFile({store, accessor, contextPath, isDetached, dst}, listener);
        } else if (action.type === "signObject") {
            return signObject({store, accessor, contextPath, isDetached, dst}, listener);
        }
    }

    buildRequest(requestTemplate) {
        const { pathParams } = requestTemplate;
        const request = { ...requestTemplate };
        request.pathParams = this.buildPathParams(pathParams);
        return request;
    }

    buildPathParams(paramsTemplate) {
        return paramsTemplate.map(param => {
            if (typeof param === 'object') {
                return appStore.getStore(param.store).get(param.path);
            }
            return param;
        });
    }

    // addParamsToPath(path, params) {
    //   const queryParams = $.get(params, 'query');
    //   if (queryParams) {

    //   }
    //   return path;
    // }
    setNoReload = (value) => {
        this.noReload = value;
    };

    executeRequestAction(action, context = {}, actionName, flowName) {
        const { onSuccess } = action;
        const reqTempl = merge({}, action.request);
        // console.log('storePath', storePath);
        let {path, refId} = context;
        if(refId && !path) {
            const globalRefsStore = appStore.getDataStore('globalRefsStore');
            path = globalRefsStore.get('refs.contextPath.' + refId);
        }
        let globalExecuteActionsStore = this.getGlobalExecuteActionsStore();
        const executeActions = globalExecuteActionsStore.get('executeActions');
        let storePath = storeTemplToPath(onSuccess,'.',path)//templToPath(onSuccess, '.');
        let pathTempl = refId ? refId + "." + onSuccess : onSuccess;
        // let store = appStore.getDataStore(storePath);
        let store = appStore.getDataStore(pathTempl);
        const method = $.get(reqTempl, 'options.method');
        const condition = method !== "save" && isNewObjectPath(path) && !action.force;
        if (condition) {
            store.setState(store.state, { isNew: true });
            // store.set('isLoading',false)
            executeActions.set(actionName, false)
            $.set(store, 'isLoading', false);
            store.makeObservable({ asMap: true });
            return;
        }

        const api = this.getAPI(action.api);
        const {localeStore,intl} = context;
        let request = (context.request ? merge(reqTempl, context.request) : reqTempl);
        const oldPath = request.path || request.options.path;
        request = {
            ...request,
            ...{ path: templToPath(request.path || request.options.path, '/',path).replace(/\/new$/, '') }
        };
        let bodyTempl = $.get(request, 'options.body');
        let body;

        if (bodyTempl) {
            bodyTempl = refId ? refId + "." + bodyTempl : bodyTempl;
            storePath = storeTemplToPath(bodyTempl,'.',path);
            pathTempl = bodyTempl;
            // store = appStore.getDataStore(storePath);

            store = appStore.getDataStore(bodyTempl);
            // if (!store) {
            //    storePath = storePath.substring(0, storePath.lastIndexOf('.')) + '.new';
            //    store = appStore.getDataStore(storePath);
            // }
            // store.set('isLoading',true)

            body = store.stateJS;
            request.options.body = JSON.stringify(body);
        }
        const contextRequest = { request, storePath: pathTempl, localeStore, intl, store, action, setterNoReload: this.setNoReload };
        if (request.options.method === 'save') {
            if($.get(body, 'id')) {
                request.options.method = 'put';
                request.path = oldPath.endsWith("/{id}")
                    ? oldPath
                    : oldPath + "/{id}"

            } else {
                request.options.method = 'post';
                request.path = oldPath;
            }
            request.path = templToPath(request.path, '/').replace(/\/new$/, '');
            // request.options.method = $.get(body, 'id') ? 'put' : 'post';
            const StoreType = resolveStore("ServiceDesignStore");
            contextRequest.fileAttach = fileAttach.bind(this, fileApi);
            if (StoreType && store instanceof StoreType && $.get(body, 'id') && !$.get(request, "queryParams.action")) {
                $.set(request, "queryParams.action", "deployConfig");
            }
            // this.changeSecuritySchema($.get(contextRequest, 'store'));
            // this.addConfIdToUiComponents($.get(contextRequest, 'store'))
        }
        // console.log('REQUEST', request);
        // console.log(routerStore);
        if (store) {
            $.set(store, 'isLoading', true);
        }
        executeActions.set(actionName, true);
        $.set(contextRequest, 'actionName', actionName);
        $.set(contextRequest, 'flowName', flowName);
        $.set(contextRequest, 'path', path);
        $.set(contextRequest, 'engineContext', this);
        $.set(contextRequest, 'callContext', context);
        if(context.refId)
            $.set(contextRequest, 'refId', context.refId);
        $.set(contextRequest, 'globalExecuteActionsStore', globalExecuteActionsStore);
        return api.request(request, contextRequest, restResponseHandler)
    }

    changeSecuritySchema(store) {
        const securityStore = store.securityStore;
        if (securityStore && securityStore.state) {
            for (let key of securityStore.state.keys()) {
                if (!$.get(securityStore.state, key + '.permissions')) {
                    $.set(securityStore.state, key + '.permissions', [])
                }
                const properties = $.get(securityStore.state, key + '.properties');
                if (properties) {
                    for (let accessor of properties.keys()) {
                        for (let type of properties.get(accessor)) {
                            const value = type[1];
                            for (let i = 0; i < value.length; i++) {
                                const perId = $.get(value[i], 'id');
                                if ($.get(securityStore.state, key + '.permissions').indexOf(perId) === -1)
                                    $.get(securityStore.state, key + '.permissions').push(perId)
                            }
                        }
                    }
                }
            }
        }
    }

    // addConfIdToUiComponents(store){
    //    if(store && store.state && $.get(store.state,'id')){
    //       const confId = $.get(store.state,'id');
    //       const uiComponents = store.uiComponents;
    //       if(uiComponents && uiComponents.state){
    //          for(let i=0;i<uiComponents.state.length;i++){
    //             const uiComponent = uiComponents.state[i];
    //             const uiComponentConfId = $.get(uiComponent,'configurationId');
    //             if(!uiComponentConfId || uiComponentConfId!== confId) {
    //                $.set(uiComponent, 'configurationId', confId);
    //             }
    //          }
    //       }
    //    }
    // }

    executeRulesAction(action, context) {
        const { rules } = action;
        rules && rules.forEach(rule => this.applyRule(rule, context));
    }

    applyRule(rule, context) {
        if (rule.type === 'map') {
            const { from, to } = rule;
            const source = appStore.getDataStore(from.store);
            const value = source.get(from.path);
            if (!context[to.context]) {
                context[to.context] = {};
            }
            ;
            $.set(context[to.context], to.path, value);
        }
    }

    async executeFlowAction(action, context, data, actionName, componentName) {
        const { flow } = action;
        if (!flow) return;
        context = context || {};
        let globalExecuteActionsStore = this.getGlobalExecuteActionsStore();
        const errorGlobalStore = appStore.getStore('errorGlobalStore', 'ui');
        const executeActions = globalExecuteActionsStore.get('executeActions');
        executeActions.set(actionName, true);
        let i = 0;

        const globalSignStore = appStore.getStore("globalSignStore", "ui");
        if(globalSignStore.get(["state"]) === "EXECUTION" && ~globalSignStore.get(["flow_index"])) {
            i = globalSignStore.get(["flow_index"]);
        }
        let isLinkInFlow = false;

        if(!$.get(context,'pathName')){//TODO probably fix
            $.set(context,'pathName',document.location.pathname)
        }
        for (;i < flow.length; i++) {
            if(errorGlobalStore.get('failedFlow') === actionName) {
                break;

            } else if (globalSignStore.get(["state"]) === "SETTINGS") {
                break;
            }
            if($.get(context,'pathName') && !this.isSamePathNames(document.location.pathname,$.get(context,'pathName'))){
                break;
            }

            context["flow_index"] = i;
            const type = await this.executeAction(/*$.get(flow[i],'ref')*/flow[i], context, data, actionName, componentName);
            if(type === 'link' || type === "noExecute") {
                isLinkInFlow = true;
                break;
            }
        }
        // flow && flow.forEach(
        //   action => this.executeAction(action, context, data)
        // );
        if(errorGlobalStore.get('failedFlow'))
            errorGlobalStore.set('failedFlow',undefined);
        executeActions.set(actionName, false);
        return isLinkInFlow?'link':undefined;
    }

    getAPI(apiName) {
        if (!this.apis[apiName]) {
            const apiSchema = $.get(this.schema, ['func', 'apis', apiName]);
            if (apiSchema) {
                const apiConf = {...API_DEFAULT_CONF, ...apiSchema}
                this.apis[apiName] = new RestApi(apiConf);
            } else {
                const apiConf = {...API_DEFAULT_CONF, ...{context: 'api'}} // ...{context: 'api/' + apiName}}
                // const apiConf = {...API_DEFAULT_CONF, ...{context: apiName}}
                this.apis[apiName] = new RestApi(apiConf);
            }
        }
        return this.apis[apiName];
    }

    @computed
    get isPageLoading() {
        return this.pageLoading;
    }

    @action
    setPageLoading(value) {
        this.pageLoading = value;
    }
}

const engine = new Engine();

let __pathname = window.location.pathname;

const defaultPushState = window.history.pushState;

window.history.pushState = function () {
    return defaultPushState.apply(history, arguments);
}

const loadApplication = async () => {
    let app;

    app = await engine.loadApplication();
    app = $.get(app, "data.0");

    engine.pageTemplates = app;
    const contextPath = $.get(app, "main_template.contextPath");
    if (typeof engine.pageTemplates === "object" && engine.pageTemplates) {
        engine.pageTemplates.securityScope = contextPath ? getScope(contextPath) : undefined;
    }
};

const forceClearLocalStorage = () => {
    const localStorageForceClear = localStorage.getItem("localStorageForceClear");
    if (localStorageForceClear !== "true") {
        localStorage.clear();
        localStorage.setItem("localStorageForceClear", "true");
    }
}

const initializePage = (pathname) => {
    pathname = pathname || routerStore.pathname;
    if (engine.noReload) {
        engine.noReload = false;

        let ctxpath = engine.getPageContextPath();
        pathname = pathname.startsWith("/" + ctxpath) ? pathname : "/" + ctxpath;
        engine.reloadPermissions(pathname, getPathToPageContent());
        return;
    }

    if (window.location.host.indexOf('3001') > -1
        || window.location.host.indexOf('3000') > -1)
        return;
    if (pathname !== __pathname) {
        __pathname = pathname;
        if (pathname.includes('/configuration/')) {
            window.location.reload()
        } else if (pathname.includes('/api/')) {
            window.location.href = identityURL() + '/api/myProfile';
        }
    }

    let loadingTimeout;
    try {
        loadingTimeout = setTimeout(() => engine.setPageLoading(true), 200);
        let ctxpath = engine.getPageContextPath();
        const templateCtxPath = $.get(engine.pageTemplates, "main_template.contextPath")
        .split("/")
        .slice(1, 4)
        .join("/");
        const b1 = engine.loadBundlesByContext(templateCtxPath);
        const b2 = ctxpath && engine.loadBundlesByContext(ctxpath);
        const b3 = engine.loadUIComponents(ctxpath);
        const placeAtPath = getPathToPageContent();

        Promise.all([b1, b2, b3]).then(
            async (values) => {
                appStore.destroyScope('data'); //after load bundles rerender page
                engine.destroyFunction();
                localeStore.setInitialized(true);
                engine.initializeTemplate(placeAtPath,pathname);
                pathname = pathname.startsWith("/" + ctxpath) ? pathname : "/" + ctxpath;
                await engine.loadSchema(pathname, placeAtPath,values[2]);
            })
          .finally(() => {
              clearTimeout(loadingTimeout);
              engine.setPageLoading(false);
          });
    } catch (e) {
        console.log('error while schema load', e)
        clearTimeout(loadingTimeout);
        engine.setPageLoading(false);
    }
}

export { initializePage, loadApplication, forceClearLocalStorage };
export default engine;
